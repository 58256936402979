import React, { useState, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import {
    AppBar,
    CssBaseline,
    IconButton,
    Select,
    Toolbar,
    Tooltip,
    MenuItem,
    Drawer,
    Divider,
    Slide,
    Paper,
    Menu,
    Button,
} from '@material-ui/core'
import clsx from 'clsx'
import IconFlagUK from 'material-ui-flags/lib/Icon_Flag_UK'
import IconFlagES from 'material-ui-flags/lib/Icon_Flag_ES'
import { useTheme } from '@material-ui/core/styles'
import {
    mdiMenu,
    mdiExitToApp,
    mdiAccountCircle,
    mdiChevronLeft,
    mdiCloudDownload
} from "@mdi/js";
import { translationsEn, translationsEs } from '../helpers/constants'
import { useStyles } from '../styles/Styles'
import Icon from '@mdi/react'
import Sidebar from '../components/Sidebar'
import NotificationModal from '../components/modals/NotificationModal'
import AppRouter from '../components/Routing/AppRouter'
import ControlappImageModal from '../components/modals/ControlappImageModal'
import IncidentDetailModal from '../components/modals/IncidentDetailModal'
import CustomersDataFilter from '../components/CustomersDataFilter'
import GrupoControlLogo from '../components/logos/grupoControlLogo'
import DimobaServiciosLogo from '../components/logos/dimobaServiciosLogo'
import GrupoControlLogoColor from '../components/logos/grupoControlLogoColor'
import DimobaServiciosLogoColor from '../components/logos/dimobaServiciosLogoColor'
import * as authAccessors from '../store/auth/accessors'
import * as authActions from '../store/auth/actions'
import * as generalAccessors from '../store/general/accessors'
import * as generalActions from '../store/general/actions'
import * as notificationAccessors from '../store/notification/accessors'
import { browserHistory } from '../store/configureStore'
import * as constants from '../helpers/constants'
import * as notificationActions from '../store/notification/actions'
import VisitDetailModal from '../components/modals/VisitDetailModal'
import { downloadFile } from '../helpers/downloads'
import ControlappRoundIncidentModal from '../components/modals/ControlappRoundIncidentModal'
import { Box, Typography } from '@mui/material'
import ControlappRoundTasksModal from '../components/modals/ControlappRoundTasksModal'

const Main = () => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const classes = useStyles(theme)
    const [showCustomerSelector, setShowCustomerSelector] = useState(false)
    const [openUserMenu, setOpenUserMenu] = useState(null)
    const isAuthenticated = useSelector(authAccessors.getAuthenticated)
    const isDrawerOpen = useSelector(generalAccessors.getDrawerOpen)
    const isLanguageSelectorOpen = useSelector(generalAccessors.getLanguageSelectorOpen)
    const isNotificationModalOpen = useSelector(notificationAccessors.getShowModal)
    const language = useSelector(generalAccessors.getLanguage)
    const companies = useSelector(generalAccessors.getCompanies)
    const selectedCompany = useSelector(generalAccessors.getSelectedCompany)
    const isIncidentDetailModalOpen = useSelector(generalAccessors.getIncidentDetailModalOpen)
    const userName = useSelector(generalAccessors.getUserName)
    const companyChangeDisabled = useSelector(generalAccessors.getCompanyChangeDisabled)
    const isVisitDetailModalOpen = useSelector(generalAccessors.getVisitDetailModalOpen)
    const isControlAppImageModalOpen = useSelector(generalAccessors.getControlappImageModalOpen)
    const isControlAppRoundTasksModalOpen = useSelector(generalAccessors.getControlappRoundTasksModalOpen)
    const isControlAppRoundIncidentModalOpen = useSelector(generalAccessors.getControlappRoundIncidentModalOpen)
    const expired = useSelector(authAccessors.getExpired)
    const canReadRegistrationRequestsTool = useSelector(authAccessors.getCanReadRegistrationRequestsTool)
    const handleDrawerOpenButtonClick = () => {
        dispatch(generalActions.setDrawerOpen(true))
    }
    const handleDrawerCloseButtonClick = () => {
        dispatch(generalActions.setDrawerOpen(false))
    }
    const handleLogoutButtonPress = () => {
        setOpenUserMenu(null)
        setShowCustomerSelector(false)
        dispatch(authActions.logout())
    }
    const handleLanguageSelectorClose = () => {
        dispatch(generalActions.setLanguageSelectorOpen(false))
    }
    const handleLanguageSelectorOpen = () => {
        dispatch(generalActions.setLanguageSelectorOpen(true))
    }
    const handleLanguageSelectorChange = (event) => {
        dispatch(generalActions.selectLanguage(event.target.value))
    }
    const handleCompanyChange = (event, value) => {
        dispatch(generalActions.selectCompany(event.target.value))
    }
    const changeShowCustomerSelector = () => {
        setShowCustomerSelector(!showCustomerSelector)
    }
    const handleChangeCustomer = () => {
        setShowCustomerSelector(false)
    }
    const handleUserMenuOpen = (event) => {
        setOpenUserMenu(event.currentTarget)
    }
    const handleUserMenuClose = () => {
        setOpenUserMenu(null)
    }
    const CompanyLogo = (props) => {
        if (props.company.company_code === '01')
            return <GrupoControlLogo />
        if (props.company.company_code === '02')
            return <DimobaServiciosLogo />
    }
    const CompanyLogoColor = (props) => {
        if (props.company.company_code === '01')
            return <GrupoControlLogoColor />
        if (props.company.company_code === '02')
            return <DimobaServiciosLogoColor />
    }
    const handleManualDownloadButtonPress = () => {
        downloadFile(
            process.env.PUBLIC_URL + '/Manual de usuarios v1.0.pdf',
            'Manual de usuarios.pdf'
        )
    }
    const handleServicerManualDownloadButtonPress = () => {
        downloadFile(
            process.env.PUBLIC_URL + '/Manual de servicers v1.0.pdf',
            'Manual de servicers.pdf'
        )
    }

    useEffect(() => {
        if (expired) {
            browserHistory.push(constants.paths.login)
            notificationActions.setError(
                language === 'es'
                    ? translationsEs.errorSessionExpired
                    : translationsEn.errorSessionExpired
            )
            notificationActions.showModal()
        }
    }, [expired, language])

    return (
        <div className={classes.root}>
            <CssBaseline />
            {isAuthenticated && (
                <AppBar
                    position='absolute'
                    className={clsx(
                        classes.appBar,
                        isDrawerOpen && classes.appBarShift
                    )}
                >
                    <Toolbar className={classes.toolbar}>
                        {isAuthenticated && (
                            <Tooltip
                                title={
                                    language === 'es'
                                        ? translationsEs.showTitles
                                        : translationsEn.showTitles
                                }
                            >
                                <IconButton
                                    edge='start'
                                    color='inherit'
                                    aria-label='open drawer'
                                    onClick={handleDrawerOpenButtonClick}
                                    className={clsx(
                                        classes.menuButton,
                                        isDrawerOpen && classes.menuButtonHidden
                                    )}
                                >
                                    <Icon path={mdiMenu} size={1} />
                                </IconButton>
                            </Tooltip>
                        )}
                        <Typography
                            component='h1'
                            variant='h6'
                            color='white'
                            noWrap
                            className={classes.title}
                        >
                            {language === 'es' ? constants.translationsEs.customerPortal.toUpperCase() : constants.translationsEn.customerPortal.toUpperCase()}
                        </Typography>
                        {isAuthenticated && (
                            <Select
                                open={isLanguageSelectorOpen}
                                onClose={handleLanguageSelectorClose}
                                onOpen={handleLanguageSelectorOpen}
                                value={language}
                                onChange={handleLanguageSelectorChange}
                                inputProps={{
                                    classes: {
                                        icon: classes.selectIcon,
                                    },
                                }}
                            >
                                <MenuItem value='es'>
                                    <Tooltip
                                        title={
                                            language === 'es'
                                                ? translationsEs.spanish
                                                : translationsEn.spanish
                                        }
                                    >
                                        <IconButton>
                                            <IconFlagES />
                                        </IconButton>
                                    </Tooltip>
                                </MenuItem>
                                <MenuItem value='en'>
                                    <Tooltip
                                        title={
                                            language === 'es'
                                                ? translationsEs.english
                                                : translationsEn.english
                                        }
                                    >
                                        <IconButton>
                                            <IconFlagUK />
                                        </IconButton>
                                    </Tooltip>
                                </MenuItem>
                            </Select>
                        )}
                        {isAuthenticated && (
                            <>
                                <Button
                                    aria-controls='user-menu'
                                    aria-haspopup='true'
                                    onClick={handleUserMenuOpen}
                                >
                                    <Typography
                                        color='white'
                                        className={classes.userAccountButton}
                                    >
                                        <span>{userName}&nbsp;</span>

                                        <Icon path={mdiAccountCircle} size={1.5} />
                                    </Typography>
                                </Button>
                                <Menu
                                    id='user-menu'
                                    anchorEl={openUserMenu}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    keepMounted
                                    open={Boolean(openUserMenu)}
                                    onClose={handleUserMenuClose}
                                >
                                    <MenuItem onClick={handleManualDownloadButtonPress}>
                                        <IconButton
                                            color='inherit'
                                            size='small'
                                        >
                                            {language === 'es'
                                                ? translationsEs.downloadUserManual
                                                : translationsEn.downloadUserManual}
                                            &nbsp;
                                            <Icon path={mdiCloudDownload} size={1} />
                                        </IconButton>
                                    </MenuItem>
                                    {canReadRegistrationRequestsTool && (
                                        <MenuItem onClick={handleServicerManualDownloadButtonPress}>
                                            <IconButton
                                                color='inherit'
                                                size='small'
                                            >
                                                {language === 'es'
                                                    ? translationsEs.downloadServicerManual
                                                    : translationsEn.downloadServicerManual}
                                                &nbsp;
                                                <Icon path={mdiCloudDownload} size={1} />
                                            </IconButton>
                                        </MenuItem>
                                    )}
                                    <Divider />
                                    <MenuItem onClick={handleLogoutButtonPress}>
                                        <IconButton
                                            color='inherit'
                                            size='small'
                                        >
                                            {language === 'es'
                                                ? translationsEs.logout
                                                : translationsEn.logout}
                                            &nbsp;
                                            <Icon path={mdiExitToApp} size={1} />
                                        </IconButton>
                                    </MenuItem>
                                </Menu>
                            </>
                        )}
                    </Toolbar>
                </AppBar>
            )}
            {isAuthenticated && (
                <Drawer
                    variant='permanent'
                    classes={{
                        paper: clsx(
                            classes.drawerPaper,
                            !isDrawerOpen && classes.drawerPaperClose
                        ),
                    }}
                    open={isDrawerOpen}
                >
                    <Box className={classes.toolbarIcon} elevation={1}>
                        <Tooltip
                            title={
                                language === 'es'
                                    ? translationsEs.hideTitles
                                    : translationsEn.hideTitles
                            }
                        >
                            <IconButton
                                onClick={handleDrawerCloseButtonClick}
                                className={classes.iconButton}
                                style={{ color: 'white' }}
                            >
                                <Icon path={mdiChevronLeft} size={1} />
                            </IconButton>
                        </Tooltip>
                        <Select
                            id='company'
                            value={selectedCompany ? selectedCompany : ''}
                            className={classes.companyDropdown}
                            onChange={handleCompanyChange}
                            renderValue={(value) => (
                                <MenuItem value={value}>
                                    {value && (
                                        <CompanyLogo company={value} />
                                    )}
                                </MenuItem>
                            )}
                            inputProps={{
                                classes: {
                                    icon: classes.selectIcon,
                                },
                            }}
                            disabled={companyChangeDisabled}
                        >
                            {companies.map((company, index) => (
                                <MenuItem
                                    value={
                                        selectedCompany &&
                                            selectedCompany.company_code ===
                                            company.company_code
                                            ? selectedCompany
                                            : company
                                    }
                                    key={index}
                                >
                                    <CompanyLogoColor company={company} />
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                    <Divider />
                    <Sidebar onChangeCustomer={changeShowCustomerSelector} showCustomerSelector={showCustomerSelector} />
                </Drawer>
            )}
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Slide
                    direction='right'
                    in={showCustomerSelector}
                    mountOnEnter
                    unmountOnExit
                >
                    <Paper className={classes.customerSelectorSlider} elevation={1}>
                        <CustomersDataFilter
                            onChangeCustomer={handleChangeCustomer}
                        />
                    </Paper>
                </Slide>
                <AppRouter />

                {isIncidentDetailModalOpen && <IncidentDetailModal />}
                {isVisitDetailModalOpen && <VisitDetailModal />}
                {isControlAppImageModalOpen && <ControlappImageModal />}
                {isControlAppRoundIncidentModalOpen && <ControlappRoundIncidentModal />}
                {isControlAppRoundTasksModalOpen && <ControlappRoundTasksModal />}

                {isNotificationModalOpen && <NotificationModal />}
            </main>
        </div>
    )
}

export default Main
